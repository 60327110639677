.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.active-self:hover {
    background-color: #77838f !important;
    color: #fff !important;
    box-shadow: 0 4px 11px rgb(119 131 143 / 35%) !important;
}
.active-self-focus {
    background-color: #77838f !important;
    color: #fff !important;
    box-shadow: 0 4px 11px rgb(119 131 143 / 35%) !important;
}

.fade-in-anim {
    animation: fadeIn 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* update at - 25-09-2023 */

.bg-footer-light {
    --bs-bg-opacity: 1;
    background-color: rgba(248, 249, 250, 1) !important;
}
.dark-mode .bg-footer-light {
    --bs-bg-opacity: 1;
    background-color: rgba(21, 21, 21, 1) !important;
}

.gap-2 {
    gap: 0.5rem !important;
}

.social-media-icon {
    color: #000000;
    padding: 8px;
    border: none;
    background-color: #e9ecef;
    border-radius: 50%;
    text-align: center;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.dark-mode .social-media-icon {
    color: #ffffff;
    background-color: #222;
}
.social-media-icon:hover {
    background-color: #dee2e6;
    color: #000000;
}

.fs-sm {
    font-size: 0.78rem;
}
.list-unstyled li a {
    color: #000000 !important;
}
.dark-mode .list-unstyled li a {
    color: #ffffff !important;
}
.go-to-top-btn,
img {
    color: #000000 !important;
}
.dark-mode .go-to-top-btn,
img {
    color: #ffffff !important;
}

.info-page-title {
    background-color: #081d35;
}
/* updated at - 27-09-2023 */

.slid-container {
    height: 35px;
    width: calc(100% - 88px);
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    justify-content: center;
}
.slid-container-two {
    height: 35px;
    width: 88px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    justify-content: center;
}
.first-slid,
.second-slid {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.first-slid {
    top: 0;
}
.second-slid {
    top: 100%;
}
.first-slid-on {
    animation: slide-one 2s;
    animation-fill-mode: forwards;
}
.second-slid-on {
    animation: slide-two 2s;
    animation-fill-mode: forwards;
}
.first-slid-rev {
    animation: slide-one-rev 2s;
    animation-fill-mode: forwards;
}
.second-slid-rev {
    animation: slide-two-rev 2s;
    animation-fill-mode: forwards;
}

@keyframes slide-one {
    from {
        top: 0;
        opacity: 1;
    }
    to {
        top: -100%;
        opacity: 0;
    }
}
@keyframes slide-two {
    from {
        top: 100%;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}
@keyframes slide-one-rev {
    from {
        top: -100%;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}
@keyframes slide-two-rev {
    from {
        top: 0;
        opacity: 1;
    }
    to {
        top: 100%;
        opacity: 0;
    }
}

.canvasjs-chart-credit {
    display: none !important;
}

.canvasjs-chart-canvas {
    background-color: transparent !important;
}

.mode-svg-icons {
    opacity: 0.6;
}